body {
  text-align: center;
  background-color: var(--bs-red);
  width: 100vw;
  overflow-x: hidden;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 16px;
  word-break: keep-all;

  .fit-width {
    width: 100vw !important;
    overflow-x: hidden;
  }

  img {
    max-width: 100%;
  }

  .font-grotesk {
    font-family: "Roc Grotesk";
    font-weight: 500;
    letter-spacing: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: never;
  }

  .font-roboto {
    font-family: 'Roboto', 'Noto Sans KR', sans-serif;
    letter-spacing: 0;
  }

  .hidden {
    display: none;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .App {
    .marquee-text {
      font-size: 23px;
      letter-spacing: -1.75px;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }

    .homepage {
      background-position: top;
      background-repeat: no-repeat;
      background-size: 120% auto;
    }

    .section-heading {
      width: 100%;
      position: relative;
      padding-bottom: 40%;

      @media (max-width: 767px) {
        width: 150%;
        margin-left: -25%;
        padding-bottom: 50%;
      }

      .heading-text {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 1;
        width: 60%;

        @media (max-width: 767px) {
          width: 80vw;
        }
      }

      .heading-object {
        position: absolute;
        right: 0;

        &.heading-object-1 {
          top: 8%;
          left: -6%;
          width: 16%;
        }

        &.heading-object-2 {
          top: 10%;
          left: 55%;
        }

        &.heading-object-3 {
          bottom: -7%;
          right: 52%;
          width: 21%;
        }

        &.heading-object-4 {
          right: 19%;
          top: -6%;
        }

        &.heading-object-5 {
          right: 30%;
          bottom: 19%;
        }

        &.heading-object-6 {
          right: 12%;
          bottom: 10%;
          width: 12%;
        }

        &.heading-object-7 {
          bottom: 2%;
          left: 6%;
          z-index: 2;
          width: 21%;
        }

        &.heading-object-8 {
          top: 4%;
          left: 18%;
          width: 18%;
        }

        &.heading-object-9 {
          bottom: 31%;
          right: 18%;
          width: 13%;
          z-index: -1;
        }

        &.heading-object-10 {
          bottom: 0%;
          right: -5%;
          z-index: -1;
        }

        &.heading-object-11 {
          top: 14%;
          right: 3%;
          width: 13%;
        }
      }

      .float1 {
        -webkit-transform: translatey(0px);
        transform: translatey(0px);
        -webkit-animation: float 3s ease-in-out alternate infinite;
        animation: float 2s ease-in-out alternate infinite;
      }

      .float2 {
        -webkit-transform: translatey(0px);
        transform: translatey(0px);
        -webkit-animation: float 2s ease-in-out alternate infinite;
        animation: float 4.5s ease-in-out alternate infinite;
      }

      .float3 {
        -webkit-transform: translatey(0px);
        transform: translatey(0px);
        -webkit-animation: float 4s ease-in-out alternate infinite;
        animation: float 6s ease-in-out alternate infinite;
      }

      @-webkit-keyframes float {
        0% {
          -webkit-transform: translatey(0px);
          transform: translatey(0px);
        }

        50% {
          -webkit-transform: translatey(-15px);
          transform: translatey(-3px);
        }

        100% {
          -webkit-transform: translatey(0px);
          transform: translatey(0px);
        }
      }

      @keyframes float {
        0% {
          -webkit-transform: translatey(0px);
          transform: translatey(0px);
        }

        50% {
          -webkit-transform: translatey(-20px);
          transform: translatey(-6px);
        }

        100% {
          -webkit-transform: translatey(0px);
          transform: translatey(0px);
        }
      }
    }

    .section-stores {
      @media (max-width: 767px) {
        padding: 0 12px;
      }

      .copy-message {
        font-size: 1.2em;

        strong {
          font-weight: 700;
        }
      }

      position: relative;

      .sticker {
        z-index: 999;
        width: 15%;
        -webkit-animation: rotating 10s linear infinite;
        -moz-animation: rotating 10s linear infinite;
        -ms-animation: rotating 10s linear infinite;
        -o-animation: rotating 10s linear infinite;
        animation: rotating 10s linear infinite;
        position: absolute;
        right: 1%;
        top: -2%;

        @media (max-width: 767px) {
          top: 8%
        }

        img {
          width: 80%;
        }
      }

      .store-list {
        position: relative;

        .store-item {
          cursor: pointer;
          transition: .2s ease;
          overflow: hidden;

          .store-item-inner {
            position: relative;

            &:hover {
              .store-item-hover {
                opacity: 1;

                @media (max-width: 767px) {
                  opacity: 0;
                }
              }
            }

            .store-item-hover {
              position: absolute;
              background: black;
              padding: 1em;
              width: 100%;
              text-align: left;
              bottom: 0;
              left: 0;
              right: 0;
              color: white;
              opacity: 0;
              transition: .2s ease;

              h3 {
                font-family: 'Roboto', 'Noto Sans KR', sans-serif;
              }

              .store-item-tag {
                border-radius: 19px;
                margin: 0 5px 5px 0;
                padding: 3px 1em 4px 1em;
                line-height: 12px;
                font-size: 13px;
              }

            }
          }

        }
      }


      @-webkit-keyframes rotating {
        from {
          -webkit-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
        }

        to {
          -webkit-transform: rotate(360deg);
          -o-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }

      @keyframes rotating {
        from {
          -ms-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
        }

        to {
          -ms-transform: rotate(360deg);
          -moz-transform: rotate(360deg);
          -webkit-transform: rotate(360deg);
          -o-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
    }

    .section-service-area {
      @media (max-width: 767px) {
        padding: 0 12px;
      }

      .service-area-list {
        font-size: 1.2em;
        letter-spacing: -1px;
        font-weight: bold;

        .service-area-item {
          .service-area-item-image {
            background-color: black;
            padding: 30% 0;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            transition: .2s ease;
          }

          &:hover {
            .service-area-item-image {
              background-size: 105%;
            }
          }

          .service-area-item-text {
            .item-desc {
              font-size: .6em;
            }

            .item-title {
              font-size: 0.9em;
              letter-spacing: -1.5px;
            }

            .item-title-en {
              font-size: 1.3em;
            }
          }
        }
      }

      .service-area-bottom {
        font-size: 1.2em;

        .service-area-inner {
          letter-spacing: -1.5px;
        }

        .service-bg {
          background-size: cover;
          background-position: center center;

          @media (max-width: 767px) {
            height: 10em;
          }
        }

        p {
          font-size: 14px;
          line-height: 1.4em;
          letter-spacing: -1px;
        }
      }
    }

    .section-join {

      @media (max-width: 767px) {
        font-size: 12px;
      }

      .form-select {
        --bs-form-select-bg-img: url(./assets/icons/icon-arrow-down.svg);
        background-position: right 1.25rem center;

        option {
          color: black;
        }
      }

      ::file-selector-button,
      ::-webkit-file-upload-button {
        display: none;
      }

      input,
      textarea,
      select,
      label {
        @media (max-width: 767px) {
          font-size: 12px;
        }

        background-color: transparent;
        color: white;
        padding: .75em 1em;
        border-radius: 0;
        outline: none;
        border: 1px solid #fff;
        margin: 0;

        &::placeholder {
          color: #fff;
        }

        &:focus,
        &:checked {
          border: 1px solid #fff;
          box-shadow: none;
        }
      }

      input[type='checkbox'] {
        margin-right: .4em;
        width: 1em;
        height: 1em;
        padding: 0.6em;
        cursor: pointer;
        background-color: transparent;
        color: white;
      }

      .agreement-label {
        color: white;
        text-align: left;
        border: none;
        padding: 0;

      }

      button {
        height: 56px;
        background-color: white;
        border-radius: 28px;
        line-height: 53px;
        padding: 0 5em;
        font-weight: bold;
        font-size: 20px;

        &:hover {
          background-color: black;
        }
      }

      .form-group-attachment {
        position: relative;
      }

    }
  }

  .modal-alert {
    .modal-content {
      padding: 1.75em;
      background-color: black;
      border-radius: 0;
      color: var(--bs-orange);
      border: none;
    }

    .modal-footer {
      border: none;
      text-align: center;

      button {
        background-color: var(--bs-orange);
        color: black;
        border-radius: 30px;
        padding: 10px 20px;
      }
    }
  }

  .modal-xl {
    @media (min-width: 992px) {
      --bs-modal-width: calc(100vw - 60px);
    }
  }

  .modal-backdrop {
    background-color: rgba(0, 0, 0, .6);
  }

  .modal-store-detail {
    .modal-content {
      padding: 2em;
      background-color: black;
      color: var(--bs-orange);
      border-radius: 0;

      a {
        color: inherit;
      }

      .modal-header {
        color: var(--bs-orange);
        border: none;
        position: relative;
        justify-content: space-around;

        @media (max-width: 767px) {
          justify-content: left;
        }

        .modal-title {
          font-size: 18px;

          @media (max-width: 767px) {
            font-size: 14px;
            padding-top: 6px;
          }
        }

        .close {
          padding: 0;
          position: absolute;
          right: 0;
          font-size: 3em;
          background: transparent;
          border: none;
          cursor: pointer;

          span {
            color: var(--bs-orange);
          }

          @media (max-width: 767px) {
            font-size: 1.5em;
          }
        }
      }

      .modal-body {
        text-align: left;
        border-top: 1px solid var(--bs-orange);
        margin-top: 2em;
        padding: 1em 0 1em 0;
        box-sizing: border-box;
        overflow: hidden;
        letter-spacing: -1.5px;
        white-space: pre-line;

        p {
          margin: 0;
        }

        @media (max-width: 767px) {
          font-size: 14px;
        }

        .store-feed {
          margin-top: 5em;

          img {
            width: 100%;
          }
        }

        .row {
          border-bottom: 1px solid var(--bs-orange);
          padding: 1.4em 0;
          box-sizing: border-box;

          .second-line {
            @media (max-width: 767px) {
              padding-top: 1.4em;
              margin-top: 1.4em;
              border-top: 1px solid var(--bs-orange);
            }
          }

          &:last-child {
            border: none;
          }
        }
      }
    }
  }
}