@import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

@font-face {
  font-family: "Roc Grotesk";
  font-style: normal;
  font-weight: 100;
  src: url("~/public/fonts/RocGrotesk/RocGroteskLight.otf") format("opentype");
}

@font-face {
  font-family: "Roc Grotesk";
  font-style: normal;
  font-weight: normal;
  src: url("~/public/fonts/RocGrotesk/RocGroteskRegular.otf") format("opentype");
}

@font-face {
  font-family: "Roc Grotesk";
  font-style: normal;
  font-weight: 400;
  src: url("~/public/fonts/RocGrotesk/RocGroteskRegular.otf") format("opentype");
}

@font-face {
  font-family: "Roc Grotesk";
  font-style: normal;
  font-weight: 500;
  src: url("~/public/fonts/RocGrotesk/RocGroteskMedium.otf") format("opentype");
}

@font-face {
  font-family: "Roc Grotesk";
  font-style: normal;
  font-weight: bold;
  src: url("~/public/fonts/RocGrotesk/RocGroteskHeavy.otf") format("opentype");
}

body {
  margin: 0;
  font-family: 'Noto Sans KR', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$theme-colors: (
  'red': #E6312B,
  'yellow': #FCE34D,
  'orange': #FF5C00
);
@import "../node_modules/bootstrap/scss/bootstrap";